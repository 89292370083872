.rxc-block {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0 0 0;
  overflow: auto;
  page-break-inside: avoid;
  word-wrap: break-word;
  white-space: pre;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-family: monospace;
  font-size: 13px;
  line-height: 1.2;
  border-left: 3px solid #003adc;
  background: #333;
  color: #8f929b; }

.rxc-head {
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .rxc-head__title, .rxc-head__remove {
    flex: 1 1 auto;
    padding: 5px; }
  .rxc-head__title {
    flex: 1 1 calc(100% - 20px);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer; }
  .rxc-head__remove {
    flex: 1 1 20px;
    margin-left: 5px;
    cursor: pointer; }

.rxc-list {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0; }
  .rxc-list__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: 2px;
    border-bottom: 1px solid #222222; }
    .rxc-list__item:last-child {
      border-bottom: none; }
  .rxc-list__title, .rxc-list__name, .rxc-list__value, .rxc-list__remove {
    flex: 1 1 auto;
    padding: 5px; }
  .rxc-list__title, .rxc-list__name, .rxc-list__value {
    text-overflow: ellipsis;
    overflow: hidden; }
  .rxc-list__title {
    flex: 1 1 calc(100% - 20px);
    font-family: sans-serif;
    font-size: 16px;
    font-weight: 500; }
  .rxc-list__name {
    flex: 1 1 30%; }
  .rxc-list__value {
    flex: 1 1 calc(70% - 20px);
    margin-left: 5px; }
  .rxc-list__remove {
    flex: 1 1 20px;
    margin-left: 5px;
    cursor: pointer; }
